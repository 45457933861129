<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4> GST List</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item @click="goBack()" >Taxation </b-breadcrumb-item>
					<b-breadcrumb-item active>GST List</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
	<br>
    <div class="card mt-4">
					
					<div class="card-body">
						<div class="venue-heading">
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="3"
					md="5"
					sm="12"
					>
						<div class="w-100">
							<multiselect
							:options="venue_names"
							placeholder="Select Venue Name"
							v-model="venue_name_value"
							>
							</multiselect>
						</div>
					</b-col>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="2"
					md="4"
					sm="12"
					>
						<div class="w-100">
							<b-form-datepicker
							id="example-datepicker"
							v-model="start_date"
							placeholder="Start date"
							class="mb-2"
							:max="end_date"
							:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							}"
							></b-form-datepicker>
						</div>
					</b-col>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="2"
					md="4"
					sm="12"
					>
						<div class="w-100">
							<b-form-datepicker
							id="example-datepicker2"
							placeholder="End date"
							:min="start_date"
							v-model="end_date"
							class="mb-2"
							:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							}"
							></b-form-datepicker>
						</div>
					</b-col> 
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="4"
					md="12"
					sm="12"
					>
						<div class=" d-flex justify-content-end button-text">
							<div class="p-1">
								<b-button variant="primary" @click="applyFilter()">
									Apply</b-button
								>
							</div>
							<div class="p-1">
								<b-button variant="primary" @click="resetFilter()">
									Reset</b-button
								>
							</div>
							<!-- <div class="p-1">
								<b-button id="button_width" variant="primary" @click="AddOffline">
								<i class="mdi mdi-plus"></i> Add Booking</b-button
								>
								
								</div>
								
								<div class="p-1">
								<b-button
								id="button_width"
								@click="export_pdf()"
								variant="primary"
								>
								<i class="mdi mdi-export"></i>Export</b-button
								>
							</div> -->
						</div>
					</b-col>
				</b-row>
			</div>
			
			<div>
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="7"
					md="12"
					sm="12"
					>
						<div
						class="
						w-100
						justify-content-sm-center
						justify-content-xl-start
						justify-content-md-start
						"
						>
							<div class="mt-2 venue-flex">
								<b-form-select
								style="max-width: 90px;"
								id="select_perpage"
								v-model="pageSize"
								:options="options"
								@change="handlePageSizeChange($event)"
								></b-form-select>
								
							</div>
						</div>
					</b-col>
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="5"
					md="12"
					sm="12"
					>
						<div class="w-100 p-1">
							<b-form-input
							v-model="search"
							id="search"
							type="text"
							placeholder="Search here"
							v-on:keyup="key_search()"
							></b-form-input>
						</div>
					</b-col>
				</b-row>
			</div>
			<br>
    <b-table
				:busy="load"
				:fields="fields"
				:items="bookings"
				responsive
				hover
				bordered
				show-empty
				empty-text="No Data Available"
				class="text-center"
				>
					<template  #cell(total_booking_price)="data">
						<span v-html="currency_check(data.item.total_booking_price)"></span>
					</template>
                    <template  #cell(tax_amount)="data">
						<span v-html="currency_check(data.item.tax_amount)"></span>
					</template>
                    <template  #cell(total_amount)="data">
						<span v-html="currency_check(data.item.total_amount)"></span>
					</template>
					
				
				</b-table>
				<b-row align-v="center">
					<b-col cols="4">
						<span
						>Showing <b>{{ totalRows }}</b> out of
							<b>{{ totalData }}</b> entries.</span
						>
					</b-col>
					<b-col cols="8">
						<b-pagination
						v-model="page"
						:total-rows="count"
						:per-page="pageSize"
						@change="handlePageChange"
						prev-text="Prev"
						next-text="Next"
						align="right"
						></b-pagination>
					</b-col>
				</b-row>
     </div>
     </div>
     </div>
     
</template>

<script>
	import axios from "axios";
	export default {
		name: "mybooking",
		
		data: () => ({
			month_id:'',
            fields: [
			//  'index',
			{
				key: "Date",
				label: "Date",
				sortable: true,
			},
            {
				key: "vendor_name",
				label: "Vendor name",
				sortable: true,
			},
			{
				key: "invoice_number",
				label: "Invoice Number",
				sortable: true,
			},
			
		
			/*  {
				key: "billing_phone",
				label: "Billing Phone",
				},
				{
				key: "billing_email",
				label: "Billing Email",
				sortable: true,
			},*/
			{
				key: "total_booking_price",
				label: "Amount",
				sortable: true,
				tdClass: 'text-right',
			},
			{
				key: "tax_amount",
				label: "GST Amount",
				sortable: true,
                tdClass: 'text-right',
			},
           /*  {
				key: "total_amount",
				label: "Total Amount",
				sortable: true,
                tdClass: 'text-right',
			},  */
		
			],
			bookings: [],
			options: [10, 20, 30, 50],
			
			pageSize: 10,
			show: false,
			load: true,
			filter: "",
			search: "",
			CurrentPage: 1,
			sortBy: "created_at",
			
			page: 1,
			count: 0,
			totalData: "",
			totalRows: "",
			
			PerPage: "50",
			venue_names: [],
			start_date: "",
			end_date: "",
			booked_on: "",
			venue_name_value: "",
			curncy: "",
		}),
		methods: {
            key_search() {
				const value = "1";
				this.handlePageChange(value);
			},
			handlePageChange(value) {
				this.page = value;
				this.load = true;
				this.bookingListing();
			},
			applyFilter() {
				const value = "1";
				this.handlePageChange(value);
			},
			getRequestParams(page, pageSize, sortBy = "created_at") {
				let params = {};
				if (page) {
					params["page"] = page - 1;
				}
				if (pageSize) {
					params["size"] = pageSize;
				}
				params["sortBy"] = sortBy;
				return params;
			},
			loadInvoice()
            {
                axios.post("/admin/admin_month_tax_details",{
						month:this.month_id,
					})
					.then((resp) => {
                        if (resp.data.status_code == 200) {
						this.bookings = [];
						if (resp.data.status) {
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.bookings.length;
							var tax_amount;

							for (var i = 0; i < resp.data.bookings.length; i++) {
                                tax_amount = resp.data.bookings[i].total_booking_price*18/100;
								this.bookings.push({
									Date: this.date_format(resp.data.bookings[i].from_date),
									invoice_number: resp.data.bookings[i].invoice_number,
									vendor_name: resp.data.bookings[i].vendor_name,
									auto_increment: resp.data.bookings[i].auto_increment,
									select_month: resp.data.bookings[i].select_month,
									booking_auto_id: resp.data.bookings[i].booking_auto_id,
									//     from_date: resp.data.bookings[i].from_date,
									//       to_date: resp.data.bookings[i].to_date,
									//   billing_phone: resp.data.bookings[i].billing_phone,
									//    billing_email: resp.data.bookings[i].billing_email,
									total_booking_price:
									this.formatPrice(resp.data.bookings[i].total_invoice),
									
									balance_amount:
									this.formatPrice(resp.data.bookings[i].total_invoice),
									//booked_on: resp.data.bookings[i].booked_on,
                                   
									tax_amount: this.formatPrice(resp.data.bookings[i].total_tax_invoice),
									total_amount: this.formatPrice(parseFloat(tax_amount)+parseFloat(resp.data.bookings[i].total_booking_price)),
									
								});
							}
							this.show = false;
							this.load = false;
							} else {
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.bookings.length;
							
							this.show = false;
							this.load = false;
						}
					}
                })
            },
            currency_check(amt) {
				
				return '<i class="mdi mdi-currency-inr"></i>'+amt;
				//return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
			getProfile() {
				axios.get("/admin/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
					}
				});
			},
            formatPrice(value) {
				var num = value ? value : 0;
			return num.toLocaleString('en-IN')
			},
			date_format:function(date)
		{
			var d1 = new Date(date);
			var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
		},
		getDateOrdinal(date) {
				let day = date.getDate();
				if (day > 3 && day < 21) return 'th';
				switch (day % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}
			},
			goBack()
			{
				this.$router.go(-1)
			},
			lastroutename() {
  //let returnval = '';

  //const routerstack = this.$router.history.stack;
  const idx = this.$router.history.index;

  /* if (idx > 0) {
    returnval = routerstack[idx - 1].name;
  } */

  return idx;
}
		},
		mounted() {
			document.documentElement.scrollTop = 0;
            this.month_id = this.$route.params.id;
            this.loadInvoice();
            this.getProfile();
		},
	};
</script>

<style>
	
</style>